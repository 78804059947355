import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material";
import Popper from "@mui/material/Popper";

import { useAsyncFn } from "../../hooks/useAsyncFn";
import axios from "../../utils/axios";

import SearchIcon from "../../assets/Icons/search.png";
import CloseIcon from "../../assets/Icons/Close_round_fill.svg";
import { setCandidates as setCandidatesRequest } from "../../actions/app";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const CustomPopper = styled(Popper)(({ theme }) => ({
    top: "50px",
    "& .MuiPaper-root": {
        borderRadius: 12,
        fontSize: theme.typography.subtitle2,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        "& *": {
            fontSize: "13px",
        },
    },
}));

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
    "& .MuiFormLabel-root": {
        fontSize: "13px",
    },
    "& .MuiInputBase-root": {
        borderRadius: "1rem !important",
        fontSize: "13px !important",
        backgroundColor: alpha(theme.palette.common.white, 0.5),
    },
}));

function GlobalSearch(props) {
    const navigate = useNavigate();

    const { setCandidates } = props;

    const [showSearch, setShowSearch] = useState(false);
    const [values, setValues] = useState({ query: "", error: false });
    const [results, setResults] = useState([]);

    const { status, submit } = useAsyncFn(async () => {
        try {
            const response = await axios.get(
                `/company/search?key=${values.query}`
            );
            if (response?.data) {
                setResults(response.data.candidates);
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            throw new Error("something went wrong!");
        }
    });

    const isLoading = status === "LOADING";

    // useEffect(() => {
    //     if (results.length > 0) {
    //         setCandidates([...results]);
    //     }
    // }, [results])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isLoading) {
            if (!values.query.trim()) {
                setValues((prev) => ({ ...prev, error: true }));
            } else {
                setResults([]);
                submit();
            }
        }
    };

    return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
            <IconButton onClick={() => setShowSearch((prev) => !prev)}>
                <img
                    src={showSearch ? CloseIcon : SearchIcon}
                    width={28}
                    height={28}
                />
            </IconButton>
            {showSearch && (
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <FormControl required={true}>
                        <Autocomplete
                            disableClearable
                            size="small"
                            loading={isLoading}
                            onBlur={() => setResults([])}
                            noOptionsText="No results"
                            options={results}
                            getOptionLabel={({ name }) => name}
                            filterOptions={(options) => options}
                            renderInput={(params) => {
                                return (
                                    <RedditTextField
                                        {...params}
                                        variant="outlined"
                                        label="Search candidates"
                                        error={values.error}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: "search",
                                        }}
                                    />
                                );
                            }}
                            renderOption={(props, option) => {
                                const { name, email, workflowName } = option;
                                return (
                                    <li {...props}>
                                        <Stack spacing={0.25}>
                                            <Typography
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {name} ({email})
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                            >
                                                Drive Name: {workflowName}
                                            </Typography>
                                        </Stack>
                                    </li>
                                );
                            }}
                            PopperComponent={CustomPopper}
                            inputValue={values.query}
                            onChange={(_, newValue) => {
                                const { _id, workflow, workflowName } =
                                    newValue;
                                setCandidates([...results]);
                                localStorage.setItem("searchedCandidates", true);
                                navigate(`/applicant/${_id}`, {
                                    state: {
                                        workflowId: workflow,
                                        workflowName,
                                        sidebar: "applicants",
                                    },
                                });
                                setResults([]);
                            }}
                            onInputChange={(_, newInputValue) =>
                                setValues((prev) => ({
                                    ...prev,
                                    query: newInputValue,
                                    error:
                                        prev.error && newInputValue
                                            ? false
                                            : prev.error,
                                }))
                            }
                            sx={{
                                width: 300,
                            }}
                        />
                    </FormControl>
                </form>
            )}
        </Stack>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setCandidates: (data) => dispatch(setCandidatesRequest(data)),
});

GlobalSearch.propTypes = {
    setCandidates: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(GlobalSearch);
